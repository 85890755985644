:root {
  /* color */
  --color-primary: hsl(202, 100%, 50%);
  --color-secondary: hsl(64, 100%, 58%);

  --color-text-main: hsl(0, 0%, 9%);
  --color-text-optional: hsl(0, 0%, 50%);
  --color-text-invert: hsl(0, 0%, 100%);

  --color-bg: hsl(0, 0%, 100%);
  --color-border: hsl(196, 40%, 88%);
  --color-pad: hsl(0, 0%, 97%);
  --color-over: hsla(197, 10%, 5%, 0.3);
  --color-picture-over: hsla(197, 10%, 5%, 0);

  --color-success: hsl(86, 74%, 47%);
  --color-warning: hsl(52, 94%, 64%);
  --color-alert: hsl(10, 100%, 73%);

  /* speed motion */
  --speed-fast: 150ms;
  --speed-med: 300ms;
  --speed-slow: 600ms;

  /* transitions */
  --tr-min: color ease-in-out 150ms;
  --tr-mid: color ease-in-out 300ms;
  --tr-big: color ease-in-out 600ms;
}

/* dark theme, os  autodetect */

@media (prefers-color-scheme: dark) {
  :root {
    --color-secondary: hsl(207, 100%, 20%);
    
    --color-text-main: hsl(0, 0%, 85%);
    --color-text-optional: hsl(198, 10%, 45%);
    --color-text-invert: hsl(0, 0%, 100%);

    --color-bg: hsl(300, 5%, 11%);
    --color-border: hsl(0, 0%, 12%);
    --color-pad: hsl(300, 4%, 10%);
    --color-over: hsla(197, 10%, 5%, 0.9);
    --color-picture-over: hsla(197, 10%, 5%, 0.3);
  }
}

/* dark theme tumbler*/

html[data-theme='dark'] {
    --color-secondary: hsl(207, 100%, 20%);

    --color-text-main: hsl(0, 0%, 85%);
    --color-text-optional: hsl(198, 10%, 45%);
    --color-text-invert: hsl(0, 0%, 100%);

    --color-bg: hsl(300, 5%, 11%);
    --color-border: hsl(0, 0%, 12%);
    --color-pad: hsl(300, 4%, 10%);
    --color-over: hsla(197, 10%, 5%, 0.9);
    --color-picture-over: hsla(197, 10%, 5%, 0.3);
}

html[data-theme='light'] {
  --color-primary: hsl(202, 100%, 50%);
  --color-secondary: hsl(64, 100%, 58%);

  --color-text-main: hsl(0, 0%, 9%);
  --color-text-optional: hsl(0, 0%, 50%);
  --color-text-invert: hsl(0, 0%, 100%);

  --color-bg: hsl(0, 0%, 100%);
  --color-border: hsl(196, 40%, 88%);
  --color-pad: hsl(0, 0%, 97%);
  --color-over: hsla(197, 10%, 5%, 0.3);
  --color-picture-over: hsla(197, 10%, 5%, 0);
}

/* main style */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html,
select {
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system,
  BlinkMacSystemFont, sans-serif;
}

body {
  color: var(--color-text-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
  color: var(--color-text-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
select {
  font-weight: 700;
  line-height: 110%;
}

h1 {
  font-size: 5.8vmax;
}

h2 {
  font-size: max(20px, 3vmax);
}

h3 {
  font-size: max(18px, 2vmax);
}

h4 {
  font-size: max(16px, 1.5vmax);
}

p {
  font-size: max(16px, 2vmax);
}

small {
  font-weight: 300;
  font-size: max(12px, 1vmax);
  color: var(--color-text-optional);
}

a {
  text-decoration: none;
}

a:link {
  transition: var(--tr-min);
}

a:link,
a:visited {
  color: var(--color-text-main);
  transition: color ease-out 150ms;
}

a:hover {
  cursor: pointer;
  color: var(--color-primary);
  transition: color ease-in 150ms;
}

small a:link,
small a:visited {
  color: var(--color-text-optional);
  transition: color ease-out 150ms;
}

small a:hover {
  cursor: pointer;
  color: var(--color-primary);
  transition: color ease-in 150ms;
}

select{
  font-size: max(14px, 1.5vmax);
  color: var(--color-text-main);
  appearance: none;
  background: none;
  border: none;
  transition: all 150ms ease-out;
}

select:hover {
  cursor: pointer;
  color: var(--color-primary);
  transition: all 150ms ease-in;
}

select:focus {
  outline: none;
}


/* adaptive */

@media screen and (max-width: 640px) {
  h1 {
    font-size: 5vmax;
  }
}

/* mobile  */
/* @media (hover: none) and (pointer: coarse) and (max-width: 640px) {} */


/* effects */

.effect-fade-in {
  animation-duration: var(--speed-slow, 600ms), var(--speed-slow, 600ms);
  animation-name: fade-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.effect-fade-out {
  animation-duration: var(--speed-slow, 600ms);
  animation-name: fade-out;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.effect-shift-down {
  animation-duration: var(--speed-med, 300ms), var(--speed-slow, 600ms);
  animation-name: shift-down, fade-in;
}

@keyframes shift-down {
  from {
    transform: translateY(-8px);
  }
  to {
    transform: translateY(0);
  }
}

.effect-shift-up {
  animation-duration: var(--speed-med, 300ms), var(--speed-slow, 600ms);
  animation-name: shift-up, fade-in;
}

@keyframes shift-up {
  from {
    transform: translateY(8px);
  }
  to {
    transform: translateY(0);
  }
}

.effect-shift-left {
  animation-duration: var(--speed-med, 300ms), var(--speed-slow, 600ms);
  animation-name: shift-left, fade-in;
}

@keyframes shift-left {
  from {
    transform: translateX(8px);
  }
  to {
    transform: translateX(0);
  }
}

.effect-shift-right {
  animation-duration: var(--speed-med, 300ms), var(--speed-slow, 600ms);
  animation-name: shift-right, fadde-in;
}

@keyframes shift-right {
  from {
    transform: translateX(-8px);
  }
  to {
    transform: translateX(0);
  }
}

.effect-zoom-in {
  animation-duration: var(--speed-slow, 600ms), var(--speed-slow, 600ms);
  animation-name: zoom-in, fade-in;
}

@keyframes zoom-in {
  from {
    transform: scale(0.975);
  }
  to {
    transform: scale(1);
  }
}

.effect-zoom-out {
  animation-duration: var(--speed-med, 300ms), var(--speed-slow, 600ms);
  animation-name: zoom-out, fade-out;
}

@keyframes zoom-out {
  0% {
    transform: scale(1.25);
  }
  to {
    transform: scale(1);
  }
}
