.record {
  position: relative;
  display: flex;
  transition: all ease-out 300ms;
  background-color: var(--color-pad);
  border-radius: 1vmax;
  padding: 2vmax 3vmax 2vmax 3vmax;
  transition: all 150ms ease-out;
  justify-content: stretch;
  width: 100%;
}

.record__title {
  display: flex;
  flex-direction: column;
  gap: 0.5vmax;
}