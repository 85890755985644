.records {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.records__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 6vmax 4vmax 12vmax 4vmax;
}

.records__header {
  margin: 0 0 4vmax 0;
}

.records__title {
  margin: 0 0 4vmax 0;
}
