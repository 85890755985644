.appPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .appPage__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 6vmax 4vmax 12vmax 4vmax;
  }
  
  .appPage__header {
    display: flex;
    margin-bottom: 5vmax;
  }
  
  .appPage__head {
    display: flex;
    margin-bottom: 5vmax;
  }
  
  .appPage__cover,
  .appPage__title-text {
    margin-bottom: 2vmax;
  }
  
  .appPage__cover {
    margin-bottom: 5vmax;
    border: 1px solid hsl(0, 10%, 95%);
  }
  
  .appPage__short-description {
    margin-bottom: 5vmax;
  }
  
  .appPage__cover {
    display: flex;
    height: max(256px, 20vmax);
    width: max(256px, 20vmax);
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .appPage__content,
  .appPage__title,
  .appPage__links {
    display: flex;
    flex-direction: column;
  }

  .appPage__links {
    gap: 2vmax;
  }
  
  .appPage__comment:not(:last-child),
  .appPage__comments-title {
    margin-bottom: 2vmax;
  }
  
  .appPage__comment-text {
    margin-bottom: 0.2vmax;
  }
  