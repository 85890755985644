.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .main__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 80vmax;
    margin: 6vmax 4vmax 12vmax 4vmax;
  }
  
  .main__header {
    display: flex;
    margin-bottom: 5vmax;
  }
  
  .main__logo {
    display: flex;
    align-items: center;
  }
  
  .main__avatar {
    display: flex;
    position: relative;
    border-radius: 100%;
    width: max(40px, 3.5vmax);
    height: max(40px, 3.5vmax);
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 1vmax;
  }
  
  .main__avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .main__avatar::before {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--color-picture-over);
  }
  
  .main__title,
  .main__apps,
  .main__extensions,
  .main__plugins, 
  .main__blog {
    margin: 0 0 11vmax 0;
  }
  
  .main__title {
    max-width: 90%;
  }

  .main__headline {
    display: flex;
    flex-direction: column;
    gap: 0.3vmax;
  }

  .main__apps,
  .main__extensions,
  .main__plugins,
  .main__blog {
    display: flex;
    flex-direction: column;
  }

  .main__app-title,
  .main__extensions-title,
  .main__plugins-title,
  .main__blog-title,
  .main__links-title {
    margin-bottom: 2.5vmax;
  }
  
  .main__links {
    margin-bottom: 11vmax;
  }
  
  .main__link {
    margin-bottom: 1.5vmax;
  }

  .main__themeSwitcher {
    margin: 0 auto 1vmax 0;
  }
  
  @media screen and (max-width: 480px) {
    .main__title,
    .main__plugins {
      margin: 0 0 8vmax 0;
    }
  }
  