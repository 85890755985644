.recordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.recordPage__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 6vmax 4vmax 12vmax 4vmax;
}

.recordPage__header {
  display: flex;
  margin-bottom: 5vmax;
}

.recordPage__head {
  display: flex;
  margin-bottom: 5vmax;
}

.recordPage__cover,
.recordPage__title-text {
  margin-bottom: 2vmax;
}

.recordPage__cover {
  margin-bottom: 5vmax;
}

.recordPage__short-description {
  margin-bottom: 5vmax;
}

.recordPage__cover {
  display: flex;
  height: max(256px, 20vmax);
  width: max(256px, 20vmax);
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.recordPage__content,
.recordPage__title {
  display: flex;
  flex-direction: column;
}

.recordPage__content-picture {
  position: relative;
  overflow: hidden;
  border-radius: 1vmax;
}

.recordPage__content-picture > picture {
  width: 100%;
  height: 100%;
}

.recordPage__content-picture:before {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-picture-over);
  }

.recordPage__content-item {
  margin-bottom: 2vmax;
}

.recordPage__content-item:last-child {
  margin-bottom: 0;
}